.food-ongoing-container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: -2;
}

.box {
    background: white;
    border-radius: 30px;
    width: 30vw;
    height: 30vw;
    position: absolute;
    top: calc(50% - 30vw / 2);
    left: calc(50% - 30vw / 2 - 30px);
}

.progress-icon {
    width: 80%;
    height: 80%;
}

.swipe-card-cover {
    height: 100%;
    width: 100%;
    position: absolute;
    bottom: 0;
    background: linear-gradient(to top, black, transparent 30%);
    border-radius: 18px;
}

.cover-content {
    position: absolute;
    bottom: 10%;
    left: 5%;
    color: white;
}

.card-title {
    margin-top: 10%;
    font-size: 2vw;
    height: 20%;
}

.card-footer {
    position: fixed;
    bottom: 5%;
    width: 90%;
}

.card-footer-col {
    display: flex;
    align-items: center;
    justify-content: center;
}