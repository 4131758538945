.demographic-head {
    font-size: 2.5vw;
    padding-bottom: 100px;
    color: white;
    font-weight: bold;
    background: linear-gradient(to bottom, transparent, rgb(255, 255, 255, 0.8));
    text-shadow: 1px 1px black;
}

.demographic-head.white {
    background: linear-gradient(to bottom, transparent, rgb(255, 255, 255));
}

.demographic-head div {
    width: 70vw;
    margin-left: 10vw;
    background: rgb(0, 0, 0, 0.4);
    border-radius: 18px;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 1%;
    padding-bottom: 1%;
}

.demographic-container {
    margin-top: 200px;
}

.demographic-col {
    border-radius: 18px;
    box-shadow: 1px 1px 2px rgb(203, 202, 202);
    color: white;
    font-weight: bold;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.demographic-col-cover {
    height: 100%;
    width: 100%;
    border-radius: 18px;
    background: linear-gradient(to bottom, transparent, black);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5vw;
}

.demographic-col-cover div {
    width: 90%;
    left: 5%;
}

.demographic-title {
    font-weight: bold;
    font-size: 2vw;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #2C2A25;
}

.demographic-title.right {
    display: flex;
    justify-content: flex-end;
}

.demographic-loading {
    height: 100%;
    width: 100%;
    background: rgb(0, 0, 0, 0.9);
    position: absolute;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.highlight-word {
    color: #EFB00A;
    font-weight: bold;
    text-shadow: 1px 1px black;
}

#treemap .main-svg {
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
}

.css-13cymwt-control {
    border-top-left-radius: 18px !important;
    border-top-right-radius: 18px !important;
}