html {
  scroll-behavior: smooth;
}

.home-menu {
  padding: 15px;
  width: 100%;
  height: 100%;
  color: white;
  top: 0;
  left: 0;
  font-size: 1em;
}

.home-menu:hover {
  color: #EFB00A; /* Changes text color on hover */
}

@media (max-width: 768px) {
  #home-introduction h1 {
    font-size: 3em;
    /* Smaller font size for smaller screens */
    width: 90vw;
    /* Use more of the viewport width */
  }

  #home-introduction button {
    width: 150px;
    /* Smaller button width */
    height: 50px;
    /* Smaller button height */
    font-size: 1em;
    /* Smaller font size */
  }
}