.events {
  margin: 0;
  padding: 0;
  list-style: none;
}

.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.notes-month {
  font-size: 28px;
  text-align: center;
}

.notes-month section {
  font-size: 28px;
}

.calendar-desc-col {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar-event-img {
  border-radius: 18px;
  width: 80%;
  height: 80%;
  position: relative;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease, filter 0.3s ease;
}

.calendar-event-img:hover {
  transform: translateY(-10px);
  box-shadow: 0px 0px 10px rgb(199, 196, 196);
}

.calendar-event-img img {
  width: 100%;
  height: 100%;
  border-radius: 18px;
}