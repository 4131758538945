.home-mode {
    .swiper {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        font-size: 18px;
        height: auto;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 30px;
        color: white;
    }

}

.home-mode-section-image {
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-mode-section-image img {
    width: 100%;
    border-radius: 10px;
}
.Row:hover img {
    filter: brightness(50%);
}

.Row:hover img:hover {
    filter: brightness(50%);
}


.earth-sticky {
    position: -webkit-sticky;
    position: sticky;
    overflow: hidden;
    bottom: 0;
    height: 45vh;
}